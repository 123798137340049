<template>
  <div>
    <loading :enable="loading" />

    <b-row class="card mb-5" v-if="totalErrors > 0 || total > 0 || totalVintage > 0">
      <div class="p-3 d-flex align-items-center justify-content-between">
        <h5>Cliente: {{$route.params.client}} - {{client}}</h5>
        <a href="#" @click="exportXls">Exportar para excel</a>
      </div>

      <b-row class="p-3">
        <b-col>
          <div class="widget d-flex w-100 warning">
            <div class="d-flex align-items-start justify-content-between flex-column w-100">
              <span class="title">Imóveis no Vintage</span>
              <span class="value">{{ totalVintage }}</span>
            </div>
          </div>
        </b-col>

        <b-col>
          <div class="widget d-flex w-100 primary">
            <div class="d-flex align-items-start justify-content-between flex-column w-100">
              <span class="title">Imóveis no SUB100</span>
              <span class="value">{{ total }}</span>
            </div>
          </div>
        </b-col>

        <b-col>
          <div class="widget d-flex w-100 danger">
            <div class="d-flex align-items-start justify-content-between flex-column w-100">
              <span class="title">Erros da última importação</span>
              <span class="value">{{ totalErrors }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-row>

    <b-row class="card mb-5">
      <div class="w-100 p-3">
        <b-table
            responsive
            class="mt-3"
            :items="filtered"
            :fields="fields"
            striped
            bordered
            hover
            show-empty
        >
          <template v-slot:empty>
            <h4 class="text-center">Nenhum Resultado encontrado</h4>
          </template>

          <template v-slot:cell(actions)="data">
            <div class="d-flex align-items-center justify-content-center">
              <a :href="`http://vintage.sub100.com.br/imoveis/${data.item.reference}`" target="_blank">Ver no Vintage</a>
            </div>
          </template>

          <template slot="top-row" slot-scope="{ fields }" v-if="items.length">
            <td v-for="field in fields" :key="field.key">
              <b-form-input v-if="field.key !== 'actions'" v-model="filters[field.key]" :placeholder="field.label"/>
            </td>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </b-row>
  </div>
</template>

<script>
import HighlightService from "@/services/highlights/HighlightService";
import _ from "underscore";
import Loading from "@/components/shared/loading";
import logsService from "@/services/logs/LogsService";
import * as XLSX from "xlsx";
export default {
  components: {Loading},
  data() {
    return {
      loading: false,
      totalVintage: 0,
      total: 0,
      totalErrors: 0,
      client: null,
      fields: logsService.getFieldsTable(),
      clients: [],
      clientSearch: '',
      clientCities: [],
      items: [],
      filters: {
        reference: '',
        operation: '',
        reason: ''
      },
      filter: {
        advertiser: null,
      }
    }
  },
  async mounted() {
    if(localStorage.getItem('importer-auth') !== 'bf:P5z#4n0}2kH[y') {
      this.$router.push({name: 'login'});
    }

    await this.getAll({
      advertiser: this.$route.params.client,
      lot: this.$route.params.lot
    });
  },
  methods: {
    exportXls() {

      let maxLength = 0;
      const data = this.items.map(item => {
        if(maxLength < item.reason.length) {
          maxLength = item.reason.length;
        }
        return {
          'Referência Sub100': item.reference,
          'Código do imóvel do cliente': item.external_reference,
        }
      })

      const worksheet = XLSX.utils.json_to_sheet(data);
      worksheet['!cols'] = [ {wch:20}, {wch:28}];
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Erros");
      XLSX.writeFile(workbook, `Erros de importação - ${this.client}.xlsx`, { compression: true });
    },
    async getAll(filter) {
      this.loading = true;
      this.total = 0;
      this.totalVintage = 0;
      this.totalErrors = 0;
      await logsService.getList(filter).then((response) => {
        this.total = response.data.total;
        this.totalVintage = response.data.totalVintage;
        this.totalErrors = response.data.totalErrors;
        this.client = response.data.client;

        this.items = response.data.data;
        this.loading = false;

      });
    },
    async loadClients(query) {
      await HighlightService.getClients(query).then((response) => {
        const {data} = response;
        this.clients = data.map((item) => {
          return {
            value: item.id,
            name: item.subsee_reference + ' - ' + item.name
          }
        })
      });
      this.loading = false;
    },
    handleClearFilter() {
      this.filter = {
        advertiser: null,
      };
      this.getAll();
    },
    handleFilter() {
      if(this.filter.advertiser) {
        this.getAll(this.filter);
      }
    },
  },
  computed: {
    filtered () {
      return this.items.filter(item => {
        return Object.keys(this.filters).every(key =>
            String(item[key]).includes(this.filters[key]))
      });
    }
  },
  watch: {
    clientSearch: _.debounce(function(addr) {
      if(!addr) {
        this.filter.advertiser = null;
      }
      this.loadClients(addr)
    }, 500)
  }
}
</script>

<style scoped lang="scss">
.widget {
  border-radius: .32rem;
  padding: 10px;
  background: #6769f0;

  .title {
    font-weight: 600;
    color: #fff;
  }

  .value {
    font-weight: 600;
    font-size: 1.5rem;
    color: #fff;
  }
}
</style>